<template>
  <div id="app" class="mt-3">
    <phrase-service v-slot="{ categories, getPhrasesGivenCategory, onDrop }">
      <b-container
        fluid
        v-on:drop.prevent="onDrop($event)"
        v-on:dragover.prevent
        v-on:dronleave.prevent
      >
        <b-jumbotron
          v-if="!categories || categories.length < 1"
          header="Drag a CSV PhrasePhlow phrases file here."
        />
        <b-tabs pills v-else>
          <b-tab
            v-for="(category, i) in categories"
            v-bind:key="i"
            v-bind:title="category"
          >
            <clipboard-service v-slot="{ copy }">
              <b-row class="mt-2">
                <b-col
                  sm="6"
                  md="4"
                  lg="3"
                  class="my-2"
                  v-for="(phrase, phraseIndex) in getPhrasesGivenCategory(
                    category
                  )"
                  v-bind:key="phraseIndex"
                >
                  <b-button
                    squared
                    variant="primary"
                    class="text-left d-flex flex-column"
                    v-on:click="onClick(phrase, copy)"
                  >
                    <h4>
                      <b-badge pill variant="secondary" class="px-3 py-2">
                        {{ phrase.title }}
                      </b-badge>
                    </h4>
                    <div>
                      {{ truncate(phrase.copyText) }}
                      <span v-b-popover.hover="phrase.copyText">...</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </clipboard-service>
          </b-tab>
        </b-tabs>
      </b-container>
    </phrase-service>
  </div>
</template>

<script>
import PhraseService from "./components/PhraseService.vue";
import ClipboardService from "./components/ClipboardService.vue";

export default {
  name: "App",
  components: { PhraseService, ClipboardService },
  methods: {
    truncate(s) {
      if (s && s.substr) return s.substr(0, 50);
      return null;
    },

    onClick(phrase, copy) {
      copy(phrase.copyText);
      this.$bvToast.toast(phrase.copyText, {
        title: "Added to Clipboard",
        solid: true,
        toaster: "b-toaster-bottom-full",
        variant: "primary",
      });
    },
  },
};
</script>
