var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3",attrs:{"id":"app"}},[_c('phrase-service',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var categories = ref.categories;
var getPhrasesGivenCategory = ref.getPhrasesGivenCategory;
var onDrop = ref.onDrop;
return [_c('b-container',{attrs:{"fluid":""},on:{"drop":function($event){$event.preventDefault();return onDrop($event)},"dragover":function($event){$event.preventDefault();},"dronleave":function($event){$event.preventDefault();}}},[(!categories || categories.length < 1)?_c('b-jumbotron',{attrs:{"header":"Drag a CSV PhrasePhlow phrases file here."}}):_c('b-tabs',{attrs:{"pills":""}},_vm._l((categories),function(category,i){return _c('b-tab',{key:i,attrs:{"title":category}},[_c('clipboard-service',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var copy = ref.copy;
return [_c('b-row',{staticClass:"mt-2"},_vm._l((getPhrasesGivenCategory(
                  category
                )),function(phrase,phraseIndex){return _c('b-col',{key:phraseIndex,staticClass:"my-2",attrs:{"sm":"6","md":"4","lg":"3"}},[_c('b-button',{staticClass:"text-left d-flex flex-column",attrs:{"squared":"","variant":"primary"},on:{"click":function($event){return _vm.onClick(phrase, copy)}}},[_c('h4',[_c('b-badge',{staticClass:"px-3 py-2",attrs:{"pill":"","variant":"secondary"}},[_vm._v(" "+_vm._s(phrase.title)+" ")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.truncate(phrase.copyText))+" "),_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(phrase.copyText),expression:"phrase.copyText",modifiers:{"hover":true}}]},[_vm._v("...")])])])],1)}),1)]}}],null,true)})],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }