<script>
import { get, set } from "idb-keyval";
import Papa from "papaparse";

const phrasesKey = "phrases";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  data() {
    return {
      phrases: null,
    };
  },
  computed: {
    categories() {
      if (!this.phrases || !this.phrases.map) return [];
      return this.phrases.map((p) => p.category).filter(onlyUnique);
    },
  },

  created() {
    this.get();
  },

  methods: {
    get() {
      get(phrasesKey).then((a) => (this.phrases = a));
    },

    set(phrasesJson) {
      set(phrasesKey, phrasesJson).then(() => this.get());
    },

    onDrop(e) {
      const files = e.dataTransfer.files;

      if (files.length !== 1) {
        console.error(`files.length should be 1 but is ${files.length}`);
        return;
      }

      Papa.parse(files[0], {
        header: true,
        complete: (results) => this.set(results.data)
      });
    },

    getPhrasesGivenCategory(category) {
      return this.phrases.filter((a) => a.category === category);
    },
  },

  render() {
    return this.$scopedSlots.default({
      phrases: this.phrases,
      categories: this.categories,
      getPhrasesGivenCategory: this.getPhrasesGivenCategory,
      onDrop: this.onDrop,
    });
  },
};
</script>
