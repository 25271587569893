<script>
import copy from "copy-to-clipboard";

export default {
  render() {
    return this.$scopedSlots.default({
      copy: text => copy(text)
    });
  }
};
</script>